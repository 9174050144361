import React, { useState, useEffect, useContext } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AuthContext } from "../../../../auth/AuthProvider";
import { useTranslation } from "react-i18next";
import { showSuccessMessage } from "../../../FormUI/Toast";
import moment from "moment";
import { getUserProfile, resetPassword } from "../../../../api/usersApi";
import LockIcon from "@material-ui/icons/Lock";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  subField: {
    backgroundColor: theme.overrides.MuiAvatar.colorDefault.backgroundColor,
    borderRadius: 4,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.6),
    color: theme.palette.grey[500],
  },

  logo: {
    marginTop: theme.spacing(0.3),
    position: "absolute",
  },

  txt: {
    color: theme.palette.secondary.dark,
    fontWeight: "600",
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(0.4),
    fontSize: "18px",
  },
}));

export default function ResetPwPopUp(props) {
  const classes = useStyles();
  const { open, handleClose, head } = props;
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();

  const [userDetails, setUserDetails] = useState(null);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getResetDetails = async () => {
    await getUserProfile(
      { clientId: profile.clientId, userId: profile.userId },
      auth.getToken()
    )
      .then((user) => {
        setUserDetails(user);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getResetDetails();
  }, [setUserDetails]);

  const schema = yup.object().shape({
    userName: yup.string().required(t("messages.required")),
    oldPassword: yup.string().required(t("messages.required")),
    // newPassword: yup.string().required(t("messages.required")),
    // confirmPassword: yup
    //   .string()
    //   .oneOf(
    //     [yup.ref("newPassword"), null],
    //     t("messages.confirmPwMustMatchToNewPw")
    //   )
    //   .required(t("messages.required")),
    newPassword: yup
      .string()
      .required(t("messages.required"))
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])\w+/,
        t("messages.passwordUpperLowerCase")
      )
      .matches(/\d/, t("messages.passowrdNumberCase"))
      .matches(
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        t("messages.passowrdSpecialCharacterCase")
      )
      .min(8, t("messages.passwordMinimum8Characters")),
    confirmPassword: yup.string(),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [values, setValues] = useState({
    userName: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onSubmit = async (data) => {
    if (confirmPasswordMessage != "") {
      return;
    } else {
      setLoading(true);

      const userData = {
        clientId: Number(profile.clientId) || 0,
        userId: Number(profile.userId) || 0,
        userName: data.userName || "",
        password: data.oldPassword || "",
        changePassword: data.newPassword || "",
        email: userDetails?.email || "",
        updatedBy: Number(profile.userId) || 0,
        clientTime: moment().format("yyyy-MM-DDTHH:mm") || "",
      };

      await resetPassword(userData, auth.getToken())
        .then((res) => {
          if (!res.status) {
            if (res > 0) {
              showSuccessMessage(t("messages.pwResetSuccess"));
              setLoading(false);
              closeDialogBox();
            } else {
              Object.values(res).map((value) => {
                showSuccessMessage(value, "error");
              });

              setLoading(false);
            }
          } else {
            showSuccessMessage(res.title, "error");
            setLoading(false);
          }
        })
        .catch((e) => {
          showSuccessMessage(t("messages.pwResetFailed"), "error");
          setLoading(false);
        });
    }
  };

  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");

  const onChangeValueData = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name == "confirmPassword") {
      if (values.newPassword != e.target.value) {
        setConfirmPasswordMessage(t("messages.confirmPwMustMatchToNewPw"));
      } else {
        setConfirmPasswordMessage("");
      }
    }
  };

  const closeDialogBox = () => {
    setValues((values) => ({
      ...values,
      userName: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }));
    handleClose();
  };

  return (
    <div>
      <Dialog
        onClose={closeDialogBox}
        open={open}
        className={classes.content}
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle onClose={closeDialogBox}>{head}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  name="userName"
                  label={`${t("labels.userName")}`}
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  value={values.userName || ""}
                  onChange={onChangeValueData}
                  inputRef={register}
                  error={!!errors.userName}
                  helperText={errors.userName?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  required
                  name="oldPassword"
                  label={`${t("labels.oldPassword")}`}
                  fullWidth
                  type="password"
                  variant="outlined"
                  size="small"
                  value={values.oldPassword || ""}
                  onChange={onChangeValueData}
                  inputRef={register}
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  required
                  name="newPassword"
                  label={`${t("labels.newPassword")}`}
                  fullWidth
                  type="password"
                  variant="outlined"
                  size="small"
                  value={values.newPassword || ""}
                  onChange={onChangeValueData}
                  inputRef={register}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  required
                  name="confirmPassword"
                  label={`${t("labels.confirmPassword")}`}
                  fullWidth
                  type="password"
                  variant="outlined"
                  size="small"
                  value={values.confirmPassword || ""}
                  onChange={onChangeValueData}
                  inputRef={register}
                  //error={!!errors.confirmPassword}
                  //helperText={errors.confirmPassword?.message}
                  error={!!confirmPasswordMessage}
                  helperText={confirmPasswordMessage}
                />
              </Grid>

              <Grid item xs={12} className={classes.field}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={loading}
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

function DialogTitle(props) {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <LockIcon className={classes.logo} color="primary" />
      <Typography variant="h4" className={classes.txt}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}
