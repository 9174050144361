const Logo = (props) => (
  <img
    alt="Logo"
    height="100%"
    width="220rem"
    src="/rent-all-logo.png"
    {...props}
  />
);
export default Logo;
