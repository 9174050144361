import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  chip: {
    marginRight: theme.spacing(2),
  },
  popover: {
    padding: theme.spacing(2),
  },
}));

const LinkDisplay = ({ links }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Find the latest link by created date

  const latestLink =
    links.length > 0
      ? links.reduce((prevLink, currentLink) =>
          prevLink.sentDate > currentLink.sentDate ? prevLink : currentLink
        )
      : null;

  return (
    <div className={classes.container}>
      {latestLink && (
        <div>
          <a href={latestLink.link}>
            <Typography style={{ fontSize: "0.8rem" }}>
              {latestLink && latestLink.description != null
                ? `${latestLink.description}`
                : latestLink?.description}
            </Typography>
          </a>
        </div>
      )}
      {links.length > 0 && (
        <Chip
          className={classes.chip}
          label={`+${links.length}`}
          onClick={handlePopoverOpen}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.popover}>
          {links.map((link, index) => (
            <div key={index}>
              <a href={link.link}>
                <Typography style={{ fontSize: "0.8rem" }}>
                  {link && link.description != null
                    ? `${link.description}`
                    : link?.description}
                </Typography>
              </a>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default LinkDisplay;
