import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getLocations = async (clientId, userId, token, withActive) => {
  const url = new URL(`${baseUrl}/locations`);

  url.searchParams.append("clientId", clientId);
  if (userId !== null) {
    url.searchParams.append("userId", userId);
  }
  url.searchParams.append("withActive", withActive);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCountries = async (token) => {
  const url = new URL(`${baseUrl}/locations/countries`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getStatesByCountryId = async (countryId, token, clientId) => {
  const url = new URL(`${baseUrl}/Locations/Countries/${countryId}/States`);

  if (clientId) {
    url.searchParams.append("clientId", clientId);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getLocationById = async (clientId, locationId, token) => {
  const url = new URL(`${baseUrl}/Locations/${locationId}`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveLocation = async (data, token) => {
  const url = new URL(`${baseUrl}/Locations`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getStatesByClientId = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Locations/${clientId}/States`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updateLocationDetails = async (locationId, data, token) => {
  const url = new URL(`${baseUrl}/Locations/${locationId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const deleteLocationDetails = async (
  clientId,
  userId,
  locationId,
  token
) => {
  const url = new URL(`${baseUrl}/Locations/${locationId}`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getSharedLocations = async (
  clientId,
  userId,
  locationId,
  token
) => {
  const url = new URL(`${baseUrl}/Locations/SharedLocation`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("UserId", userId);
  url.searchParams.append("LocationId", locationId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postSharedLocation = async (data, token) => {
  const url = new URL(`${baseUrl}/Locations/SharedLocation`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const postLocationImage = async (data, token) => {
  const url = new URL(`${baseUrl}/Locations/Image`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getStatesByCountry = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Locations/StateForLocation`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getLocationByState = async (id, clientId, token) => {
  const url = new URL(`${baseUrl}/Locations/LocationByState`);

  url.searchParams.append("ClientID", clientId);
  url.searchParams.append("StateId", id);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
