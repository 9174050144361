import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getRate = async (rateId, token) => {
	const url = new URL(`${baseUrl}/Rates/${rateId}`);

	url.searchParams.append("rateId", rateId);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRateNames = async ({ clientId, locationId }, token) => {
	const url = new URL(`${baseUrl}/rates/ratesnames`);

	url.searchParams.append("clientid", clientId);
	url.searchParams.append("locationid", locationId);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRateSummary = async (
	{ clientId, locationId, rateTypeName },
	token
) => {
	const url = new URL(`${baseUrl}/ratetypes/summary`);

	url.searchParams.append("clientid", clientId);
	url.searchParams.append("locationid", locationId);
	url.searchParams.append("rateTypeName", rateTypeName);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRates = async (
	{
		clientId,
		locationId,
		vehicleTypeId,
		checkInDate,
		checkOutDate,
		returnDate,
		RateName,
		agreementId,
		agreementTypeName,
		reservationId,
		packageRateName,
	},
	token
) => {
	const url = new URL(`${baseUrl}/Rates`);

	url.searchParams.append("clientid", clientId);
	url.searchParams.append("locationid", locationId);
	url.searchParams.append("VehicleTypeId", vehicleTypeId);
	url.searchParams.append("CheckinDate", checkInDate);
	url.searchParams.append("CheckOutDate", checkOutDate);
	if (returnDate) {
		url.searchParams.append("ReturnDate", returnDate);
	}
	url.searchParams.append("RateName", RateName);
	url.searchParams.append("agreementId", agreementId);
	agreementTypeName &&
		url.searchParams.append("agreementTypeName", agreementTypeName);
	reservationId && url.searchParams.append("reservationId", reservationId);
	packageRateName && url.searchParams.append("PackageName", packageRateName);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRatesDetails = async (
	clientId,
	locationId,
	vehicleTypeId,
	checkInDate,
	checkOutDate,
	RateName,
	token
) => {
	const url = new URL(`${baseUrl}/Rates`);

	url.searchParams.append("clientid", clientId);
	url.searchParams.append("locationid", locationId);
	url.searchParams.append("VehicleTypeId", vehicleTypeId);
	url.searchParams.append("CheckinDate", checkInDate);
	url.searchParams.append("CheckOutDate", checkOutDate);
	url.searchParams.append("RateName", RateName);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRateNamesAll = async (clientId, token) => {
	const url = new URL(`${baseUrl}/rates/ratesname`);
	url.searchParams.append("clientid", clientId);
	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const searchRates = async (filterValues, token) => {
	const url = new URL(`${baseUrl}/Rates/RateConfigurations`);
	for (var key in filterValues) {
		if (
			key !== null &&
			filterValues[key] !== undefined &&
			filterValues.hasOwnProperty(key) &&
			filterValues[key] !== "" &&
			filterValues[key] !== "0" &&
			filterValues[key] !== null &&
			filterValues[key] !== 0
		) {
			url.searchParams.append(key, filterValues[key]);
		}
	}
	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveRateConfig = async (data, token) => {
	const url = new URL(`${baseUrl}/Rates/RateConfiguration`);

	let options = {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options)
		.then((response) => response.json())
		.catch(handleError);
};

export const updateRateConfig = async (data, id, token) => {
	const url = new URL(`${baseUrl}/Rates/${id}/RateConfiguration`);
	let options = {
		method: "PUT",
		body: JSON.stringify(data),
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options)
		.then((response) => response.json())
		.catch(handleError);
};

export const getRateById = async (clientId, token, rateId) => {
	const url = new URL(`${baseUrl}/Rates/${rateId}/RateConfiguration`);

	url.searchParams.append("ClientId", clientId);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRateByLocation = async (
	clientId,
	token,
	locationId,
	ratename,
	vehicleTypeId
) => {
	const url = new URL(`${baseUrl}/Rates/RateConfigurations`);

	url.searchParams.append("clientId", clientId);
	url.searchParams.append("locationId", locationId);
	url.searchParams.append("ratename", ratename);
	url.searchParams.append("vehicleTypeId", vehicleTypeId);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteRateConfig = async (clientId, id, token) => {
	const url = new URL(`${baseUrl}/Rates/${id}/RateConfiguration`);

	url.searchParams.append("clientId", clientId);

	let options = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const response = await fetch(url, options);
		const responseBody = await response.text();
		const data = responseBody ? JSON.parse(responseBody) : {};
		return {
			status: response.status,
			data: data,
		};
	} catch (error) {
		handleError(error);
	}
};

export const getPackageRate = async (clientId, token) => {
	const url = new URL(`${baseUrl}/rates/PackageRates`);
	url.searchParams.append("clientid", clientId);
	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const savePackageRate = async (data, token) => {
	const url = new URL(`${baseUrl}/Rates/PackageRates`);

	let options = {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPackageRateById = async (clientId, token, packageRateId) => {
	const url = new URL(`${baseUrl}/Rates/${packageRateId}/PackageRates`);
	url.searchParams.append("clientId", clientId);
	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deletePackageRate = async (data, token) => {
	const url = new URL(`${baseUrl}/Rates/PackageRates/Delete`);
	let options = {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRateNamesByLocationIdAndVehicleTypeId = async (
	clientId,
	locationId,
	vehicleTypeId,
	token
) => {
	const url = new URL(`${baseUrl}/rates/RatesNamesforPackageRate`);

	url.searchParams.append("clientId", clientId);
	url.searchParams.append("locationId", locationId);
	url.searchParams.append("vehicleTypeId", vehicleTypeId);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPackageRateTypes = async (
	clientId,
	vehicleTypeId,
	locationId,
	checkOutDate,
	checkinDate,
	token
) => {
	const url = new URL(`${baseUrl}/rates/PackageRateTypes`);
	url.searchParams.append("clientid", clientId);
	url.searchParams.append("vehicleTypeId", vehicleTypeId);
	url.searchParams.append("locationId", locationId);
	url.searchParams.append("checkOutDate", checkOutDate);
	url.searchParams.append("checkinDate", checkinDate);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const searchGroupRates = async (filterValues, token) => {
	const url = new URL(`${baseUrl}/Rates/GroupByRateConfigurations`);
	for (var key in filterValues) {
		if (
			key !== null &&
			filterValues[key] !== undefined &&
			filterValues.hasOwnProperty(key) &&
			filterValues[key] !== "" &&
			filterValues[key] !== "0" &&
			filterValues[key] !== null &&
			filterValues[key] !== 0
		) {
			url.searchParams.append(key, filterValues[key]);
		}
	}
	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteRateByRateName = async (clientId, rateName, token) => {
	const url = new URL(`${baseUrl}/Rates/DeleteRateByRateName`);
	url.searchParams.append("clientId", clientId);
	url.searchParams.append("rateName", rateName);

	let options = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const response = await fetch(url, options);
		const responseBody = await response.text();
		const data = responseBody ? JSON.parse(responseBody) : {};
		return {
			status: response.status,
			data: data,
		};
	} catch (error) {
		handleError(error);
	}
};

export const getRateByRateName = async (clientId, token, rateName) => {
	const url = new URL(`${baseUrl}/Rates/GetRateConfigurationFromName`);

	url.searchParams.append("clientId", clientId);
	url.searchParams.append("rateName", rateName);

	let options = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(url, options)
		.then((response) => response.json())
		.catch(handleError);
};
