import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  '@global': {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    '*:focus': {
      outline: 0,
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
    '.listItemLeftPadding': {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      '@media (max-width:  420px)': {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    '.container': {
      width: '100%',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1170,
      },
    },
    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    '.container-fluid': {
      width: '100%',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 1370,
    },
    '.lg-mg-top': {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-p-top': {
      paddingTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(14)}px !important`,
      },
    },

    '.MuiAutocomplete-inputRoot, .MuiOutlinedInput-root': {
      background: '#E9ECFF',
    },
    '#rates-taxes .MuiOutlinedInput-input, #miscellaneous-charges .MuiOutlinedInput-input':
      {
        background: '#FCFCFC',
      },
    '.Mui-disabled': {
      color: '#5c5c5c',
    }, 
    '@media (min-width: 576px)' : {
      '.Custom-MuiDatePicker .MuiInputBase-input':{
        fontSize: '1rem',
      },
    },
    '@media only screen and (min-width: 960px) and (max-width:1023px)' : {
      '.Custom-MuiDatePicker .MuiInputBase-input':{
        marginRight: '-20px',
        background: 'transparent',
        fontSize: '11px',
        padding: '14px 5px',
      },
      '.Custom-MuiDatePicker .MuiIconButton-root':{
        marginRight: '-.5rem',
        background: 'transparent',
      }
    },
    '@media only screen and (min-width: 1024px) and (max-width:1200px)' : {
      '.Custom-MuiDatePicker .MuiInputBase-input':{
        padding: '13px 6px',
        fontSize: '0.7rem',
      },
      '.Custom-MuiDatePicker .MuiIconButton-root':{
        marginLeft: '-20px',
        marginRight: '-1rem',
        background: 'transparent',
      }
    },
    '@media only screen and (min-width: 1200px) and (max-width:1300px)' : {
      '.Custom-MuiDatePicker .MuiInputBase-input':{
        padding: '12px 8px',
        fontSize: '0.9rem',
      },
      '.Custom-MuiDatePicker .MuiIconButton-root':{
        marginLeft: '-20px',
        marginRight: '-1rem',
        background: 'transparent',
      }
    },
    '@media (min-width:1350px)' : {
      '.Custom-MuiDatePicker .MuiInputBase-input':{
        fontSize: '14px',
        padding: '12px 14px',
      },
      '.Custom-MuiDatePicker .MuiIconButton-root':{
        marginRight: '-1rem',
        background: 'transparent',
      }
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
