import {
  SET_CUSTOMER_DETAILS_INQUIRY,
  IS_ADDED_A_NEW_SQAURE_UP_CARD,
} from "../types/addCustomerTypes";

const initialState = {
  inquiredCustomer: {},
  isAddedANewSquareupCard: false,
};

const addCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_DETAILS_INQUIRY:
      return {
        ...state,
        inquiredCustomer: {
          ...state.inquiredCustomer,
          personalInformation: action.payload,
        },
      };
    case IS_ADDED_A_NEW_SQAURE_UP_CARD:
      return { ...state, isAddedANewSquareupCard: action.payload };
    default:
      return state;
  }
};

export default addCustomerReducer;
