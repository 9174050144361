export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_VEHICLE_ID = "SET_VEHICLE_ID";
export const SET_RESERVATION_ID = "SET_RESERVATION_ID";
export const SET_AGREEMENT_ID = "SET_AGREEMENT_ID";
export const SET_INQUIRY_ID = "SET_INQUIRY_ID";
export const SET_USER_ID = "SET_USER_ID";
export const SET_REPORT_ID = "SET_REPORT_ID";
export const SET_TEMPLATE_ID = "SET_TEMPLATE_ID";
export const SET_GPS_ID = "SET_GPS_ID";
export const SET_Credit_Note_ID = "SET_Credit_Note_ID";
export const CHANGE_TO_SINGLE_PAGE_VIEW_CUSTOMER =
  "CHANGE_TO_SINGLE_PAGE_VIEW_CUSTOMER";
export const CHANGE_TO_SINGLE_PAGE_VIEW_VEHICLE =
  "CHANGE_TO_SINGLE_PAGE_VIEW_VEHICLE";
export const CHANGE_TO_SINGLE_PAGE_VIEW_AGREEMENT =
  "CHANGE_TO_SINGLE_PAGE_VIEW_AGREEMENT";
export const CHANGE_TO_SINGLE_PAGE_VIEW_RESERVATION =
  "CHANGE_TO_SINGLE_PAGE_VIEW_RESERVATION";
