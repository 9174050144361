import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getPlannerLookupData = async (clientId, token, userId) => {
  const url = new URL(`${baseUrl}/planner/lookuplists`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPlanner = async (searchValues, clientId, token, userId) => {
  const url = new URL(`${baseUrl}/planner`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);

  for (var key in searchValues) {
    if (!Array.isArray(searchValues[key]) && searchValues[key] !== "") {
      url.searchParams.append(key, searchValues[key]);
    } else if (Array.isArray(searchValues[key]) && searchValues[key].length) {
      url.searchParams.append(key, searchValues[key]);
    }
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const printReservation = async (id, clientId, token) => {
  const url = new URL(`${baseUrl}/Reservations/${id}/Print`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const printAgreement = async (id, clientId, status, token) => {
  const url = new URL(`${baseUrl}/Agreements/${id}/Print`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("clientid", status);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};
