import { createTheme } from "@material-ui/core";

// colors
const primary = "#30D0B6";
const black = "#08134A";
const darkBlack = "rgb(36, 40, 44)";
const background = "#F9F9F9";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, 1)";
const warningDark = "rgba(253, 200, 69, .7)";
const danger = "#FF3D3D";
const darkBackground = "#f2f2f2";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// spacing
const spacing = 8;

const theme = createTheme({
  palette: {
    primary: {
      light: "#59d9c4",
      main: "#30D0B6",
      dark: "#21917f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#39426e",
      main: "#08134A",
      dark: "#050d33",
      contrastText: "#fff",
    },
    common: {
      black,
      darkBlack,
      danger,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      dark: darkBackground,
    },
    spacing,
  },
  shadow: `0px 14px 40px #F5F7FF`,
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },

  overrides: {
    MuiAvatar: {
      colorDefault: {
        color: black,
        backgroundColor: "#E9ECFF",
      },
    },
    MuiOutlinedInput: {
      input: {
        backgroundColor: "#E9ECFF",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "1rem",
        fontWeight: 450,
      },
      root: {
        fontSize: "1rem",
      },
    },
    MuiInput: {
      root: {
        fontWeight: 500,
        padding: 0,
        marginTop: 0,
        textAlign: "center",
        "&::before": {
          display: "none",
        },
        height: "2.5rem",
        color: "#000",
        borderRadius: "5px",
        backgroundColor: "#E9ECFF",
      },
      input: {
        height: "1.56rem",
        fontSize: "1rem",
        width: "100%",
        paddingLeft: spacing,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiTextField: {
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiExpansionPanel: {
      root: {
        position: "static",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItemIcon: {
      root: {
        color: primary,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${0}px solid ${borderColor}`,
      },
      root: {
        "&$selected": {
          backgroundColor: "#FAFBFF",
          borderLeft: "4px solid #30D0B6",
        },
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: black,
        fontSize: "12px",
      },
      arrow: {
        color: black,
      },
    },
    MuiDropzoneArea: {
      root: {
        color: "#808080",
        border: `2px solid ${borderColor}`,
      },
      icon: {
        color: "#050d33",
      },
    },
    MuiAccordion: {
      root: { boxShadow: "none" },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: "#E9ECFF",
        borderRadius: "4px",
      },
      expandIcon: {
        backgroundColor: "#abb2d4",
        color: "#fff",
        padding: "1px",
        marginRight: "1px",
        "&:hover": {
          backgroundColor: "#abb2d4",
        },
      },
    },
    MuiTreeItem: {
      root: {
        fontSize: 15,
        color: "#808080",
        "&$selected > $content $label": {
          backgroundColor: "transparent",
        },
        "&$selected > $content $label:hover, &$selected:focus > $content $label":
          {
            backgroundColor: "transparent",
          },
      },
    },
    MuiCheckbox: {
      root: {
        color: primary,
      },
      colorSecondary: {
        "&$checked": {
          color: primary,
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: "0.9rem",
      },
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
    },
    MuiNativeSelect: {
      root: {
        fontSize: "1rem",
        fontWeight: 450,
      },
    },
    TableContainer: {
      root: {
        "@media print": {
          overflow: "visible !important",
        },
      },
    },
    MuiToolbar: {
      root: {
        "@media print": {
          display: "none",
        },
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: black,
        "@media print": {
          backgroundColor: "#fff",
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: "1rem",
        fontWeight: 450,
      },
      head: {
        color: "#fff",
        "@media print": {
          color: "#000  !important",
        },
        backgroundColor: "#08134A",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#fff !important",
        "@media print": {
          color: "#000  !important",
        },
      },
      icon: {
        color: "#fff !important",
        "@media print": {
          color: "#000  !important",
        },
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        color: "#fff",
        backgroundColor: "#08134A",
        "@media print": {
          color: "#000  !important",
        },
      },
    },
  },

  typography: {
    fontFamily: "'Poppins','Helvetica','Arial', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "1.8rem",
      color: "#08134A",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "2rem",
      },
    },
    h2: {
      fontSize: "1.4rem",
      letterSpacing: "0px",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1.70rem",
      },
    },
    h3: {
      fontSize: "0.8rem",
      letterSpacing: "0px",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1.1rem",
      },
    },
    h4: {
      fontSize: "0.9rem",
      letterSpacing: "0px",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "1rem",
      },
    },
    h5: {
      fontSize: "0.86rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "0.96rem",
      },
    },
    h6: {
      fontSize: "0.75rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontSize: "0.75rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "1rem",
      },
    },
    subtitle1: {
      fontSize: "0.8rem",
      color: "#969BA0",
      "@media (min-width:1536px)": {
        fontSize: "1.1rem",
      },
    },
    subtitle2: {
      fontSize: "0.4rem",
      color: "#08134A",
      fontWeight: 500,
      opacity: 0.3,
      "@media (min-width:1536px)": {
        fontSize: "0.7rem",
      },
    },
    button: {
      fontSize: "0.9rem",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "1rem",
      },
    },
  },
});

export default theme;
