import * as types from "../types";
import * as Api from "../../api";

export const loadLookupLists = (clientId, token, userId) => {
  return (dispatch) => {
    return Api.getLookupLists(clientId, token, userId)
      .then((lists) => {
        dispatch(loadLookupListsSuccess(lists));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadLookupListsSuccess = (lists) => {
  return { type: types.LOAD_LOOKUP_LISTS_SUCCESS, lists };
};

export const loadInsuranceCompanies = (clientId, token) => {
  return (dispatch) => {
    return Api.getInsuranceCompanies(clientId, token)
      .then((lists) => {
        dispatch(loadInsuranceCompaniesSuccess(lists));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadInsuranceCompaniesSuccess = (lists) => {
  return { type: types.LOAD_INSURANCE_COMPANIES_SUCCESS, lists };
};

export const loadLocations = (clientId, userId, token) => {
  return (dispatch) => {
    return Api.getLocations(clientId, userId, token, true)
      .then((lists) => {
        dispatch(loadLocationsSuccess(lists));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadLocationsSuccess = (lists) => {
  return { type: types.LOAD_LOCATIONS_SUCCESS, lists };
};

export const loadVehicleTypes = (clientId, userId, token) => {
  return (dispatch) => {
    return Api.getVehicleTypes(clientId, userId, token)
      .then((lists) => {
        dispatch(loadVehicleTypesSuccess(lists));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadVehicleTypesSuccess = (lists) => {
  return { type: types.LOAD_VEHICLE_TYPES_SUCCESS, lists };
};

export const loadLeasingCompanies = (clientId, token) => {
  return (dispatch) => {
    return Api.getLeasingCompanies(clientId, token)
      .then((lists) => {
        dispatch(loadLeasingCompaniesSuccess(lists));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadLeasingCompaniesSuccess = (lists) => {
  return { type: types.LOAD_LEASING_COMPANIES_SUCCESS, lists };
};
