import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const searchUserRoles = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Roles`);
  url.searchParams.append("clientid", clientId);
  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRoleById = async (clientId, roleId, token) => {
  const url = new URL(`${baseUrl}/Roles/${roleId}`);
  url.searchParams.append("ClientId", clientId);
  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPermissions = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Roles/Permissions`);
  url.searchParams.append("ClientId", clientId);
  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveUserRole = async (data, token) => {
  const url = new URL(`${baseUrl}/Roles`);
  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const updateUserRole = async (roleId, data, token) => {
  const url = new URL(`${baseUrl}/Roles/${roleId}/Permissions`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((res) => res)
    .catch(handleError);
};

export const deleteUserRole = async (roleId, token) => {
  const url = new URL(`${baseUrl}/Roles/${roleId}`);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getPermissionsById = async (roleId, clientId, token) => {
  const url = new URL(`${baseUrl}/Roles/${roleId}/Permissions`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
