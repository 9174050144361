import i18n from "i18next";
import * as types from "../types";
import * as Api from "../../api";

export const loadUserProfile = ({ clientId, userId }, token) => {
  return (dispatch) => {
    return Api.getUser({ clientId, userId }, token)
      .then((user) => {
        dispatch(loadUserProfileSuccess(user));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadUserProfileSuccess = (user) => {

  i18n.changeLanguage(user.language)

  return { type: types.LOAD_USER_SUCCESS, user };
};

export const loadClientInfo = (clientId, token) => {
  return (dispatch) => {
    return Api.getClientLocationDetails( clientId, token)
      .then((user) => {
        dispatch(loadClientInfoSuccess(user));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadClientInfoSuccess = (user) => {
  return { type: types.LOAD_CLIENT_DETAILS_SUCCESS, user };
};