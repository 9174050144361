import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DialogTitle from "../Admin/ApplicationConfig/DialogTitle";
import HelpIcon from "@material-ui/icons/Help";
import { Button, DialogActions } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  logo: {
    position: "absolute",
    paddingBottom: 5,
  },
}));

export default function FaqModal(props) {
  const classes = useStyles();
  const { handleClose, open } = props;

  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle
        onClose={handleClose}
        icon={<HelpIcon className={classes.logo} color="primary" />}
      >
        {t("headings.faq")}
      </DialogTitle>

      <DialogContent dividers style={{ height: "41rem", overflowY: "hidden" }}>
        <iframe
          src={process.env.REACT_APP_KNOWLEDGE_BASE_URL}
          width="100%"
          height="100%"
          style={{ border: "none" }}
        ></iframe>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
