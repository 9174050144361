import { baseUrl, handleResponse, handleError } from "./apiUtils";

const getDBR = async (
  {
    clientId,
    userId,
    locationId,
    startDate,
    endDate,
    isCheckInDate,
    reportType,
    isPostMode,
  },
  token
) => {
  const url = new URL(`${baseUrl}/dms/dailybusinessreport`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("locationId", locationId);
  if (!isPostMode) {
    url.searchParams.append("startDate", startDate);
  }
  url.searchParams.append("endDate", endDate);
  url.searchParams.append("isCheckInDate", isCheckInDate);
  url.searchParams.append("reportType", reportType);
  url.searchParams.append("isPostMode", isPostMode);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getClosedContracts = async (request, token) => {
  return getDBR({ ...request, reportType: 1 }, token);
};

export const getOpenContracts = async (request, token) => {
  return getDBR({ ...request, reportType: 2 }, token);
};

export const getDepositsForDBR = async (request, token) => {
  return getDBR({ ...request, reportType: 3 }, token);
};

export const getPaymentsForDBR = async (request, token) => {
  return getDBR({ ...request, reportType: 4 }, token);
};

export const getSummaryForDBR = async (request, token) => {
  return getDBR({ ...request, reportType: 5 }, token);
};

export const postDBR = async (
  { clientId, userId, locationId, startDate, endDate, isPostMode, reportType },
  token
) => {
  const response = await fetch(`${baseUrl}/DMS/DMSPosting`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      clientId,
      userId,
      locationId,
      startDate,
      endDate,
      isPostMode,
      reportType,
    }),
  })
    .then(handleResponse)
    .catch(handleError);

  return { ...response, status: true };
};
export const getAccountTypeAdmin = async (clientId, token) => {
  const url = new URL(`${baseUrl}/dms/AccountTypeAdmin`);

  clientId && url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
export const getAccountDescription = async (clientId, token) => {
  const url = new URL(`${baseUrl}/dms/AccountDescription`);

  clientId && url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
export const removePostingFlag = async (clientId, data, token) => {
  const url = new URL(`${baseUrl}/dms/RemovePostingFlag`);
  clientId && url.searchParams.append("clientId", clientId);
  if (data.startDate) {
    url.searchParams.append("startDate", data.startDate);
  }
  if (data.endDate) {
    url.searchParams.append("endDate", data.endDate);
  }
  if (data.agreementID) {
    url.searchParams.append("agreementID", data.agreementID);
  }
  if (data.reservationID) {
    url.searchParams.append("reservationID", data.reservationID);
  }
  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const addPostingFlag = async (clientId, data, token) => {
  const url = new URL(`${baseUrl}/dms/AddPostingFlag`);
  clientId && url.searchParams.append("clientId", clientId);
  if (data.startDate) {
    url.searchParams.append("startDate", data.startDate);
  }
  if (data.endDate) {
    url.searchParams.append("endDate", data.endDate);
  }
  if (data.agreementID) {
    url.searchParams.append("agreementID", data.agreementID);
  }
  if (data.reservationID) {
    url.searchParams.append("reservationID", data.reservationID);
  }
  let options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};
