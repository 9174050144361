import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from "@material-ui/icons/Minimize";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }, HeaderNotes: {
    position: "absolute",
    right: theme.spacing(10),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  minimizeButton: {
    position: "absolute",
    right: theme.spacing(6),
    top: theme.spacing(0.2),
    color: theme.palette.grey[500],
  },
  HeaderNotes: {
    position: "absolute",
    right: theme.spacing(10),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  logo: {
    position: "absolute",
    paddingBottom: 5,
  },

  txt: {
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
    marginLeft: theme.spacing(4),
  },
}));

function DialogTitle(props) {
  const { children, onClose, onMinimize, icon, header, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      
      {icon ? (
        icon
      ) : (
        <AddCircleSharpIcon className={classes.logo} color="primary" />
      )}
      <Typography
        variant="h4"
        color="secondary"
        display="inline"
        className={classes.txt}
      >
        {children}
      </Typography>
      <p className={classes.HeaderNotes}>{header}</p>
      {onMinimize ? (
        <IconButton
          aria-label="minimize"
          className={classes.minimizeButton}
          onClick={onMinimize}
        >
          <MinimizeIcon />
        </IconButton>
      ) : null}
      {onClose ? (
         
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

export default DialogTitle;
