import { createStore, applyMiddleware, compose } from "redux";
import persistedReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
//import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";



const configureStore = (initialState) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //add support for Redux dev tools

  return createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(
      //createStateSyncMiddleware({
      //  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
      //}), 
      thunk, reduxImmutableStateInvariant()))
  );
}

const store = configureStore();

//initMessageListener(store);

export default store;
