import React from "react";
import { AuthConsumer } from "./AuthProvider";
import { PURGE } from "redux-persist";
import { useDispatch } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";

export const Logout = () => {
	const dispatch = useDispatch();
	return (
		<AuthConsumer>
			{({ logout }) => {
				Intercom("shutdown");
				logout();
				dispatch({
					type: PURGE,
					key: "root", // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
					result: () => null, // Func expected on the submitted action.
				});

				return <span>loading</span>;
			}}
		</AuthConsumer>
	);
};
