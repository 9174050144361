import {
  SET_AGREEMENT_ID,
  SET_AGREEMENT_INFO_BY_ID,
  RESET_EDIT_AGREEMENT,
} from '../types/editAgreementTypes';

const initialState = {
  id: '',
  data: {},
};

const editAgreementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGREEMENT_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_AGREEMENT_INFO_BY_ID:
      return {
        ...state,
        data: action.payload,
      };
    case RESET_EDIT_AGREEMENT:
      return {
        id: '',
        data: {},
      };
    default:
      return state;
  }
};

export default editAgreementReducer;
