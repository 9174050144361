import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getPayment = async (token, type, id) => {
  const url = new URL(`${baseUrl}/Payment/${type}Payment`);

  // eslint-disable-next-line eqeqeq
  if (type == "Reservation") {
    url.searchParams.append("ReservationId", id);
  } else {
    url.searchParams.append("AgreementID", id);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPaymentConfigs = async (body, token) => {
  const url = new URL(`${baseUrl}/Payment/ConfigurationDetails`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const getPaymentMethods = async (token, mode, clientId) => {
  const url = new URL(`${baseUrl}/Payment/Methods`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("PaymentMode", mode);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCreditCardType = async (token) => {
  const url = new URL(`${baseUrl}/Payment/CreditCardTypes`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCreditCards = async (token, id, clientId, userID) => {
  const url = new URL(`${baseUrl}/Customers/${id}/CreditCards`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userID", userID);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const makePayment = async (token, data) => {
  const url = new URL(`${baseUrl}/Payment`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

//RNTL-2472 - PayPalOrder API for 3d activation feature
export const makePayPalOrder = async (token, data) => {
  const url = new URL(`${baseUrl}/Payment/PayPalOrder`);
  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const getCountry = async (token, id) => {
  const url = new URL(`${baseUrl}/Locations/Countries`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPaymentType = async (token, clientId, type) => {
  const url = new URL(`${baseUrl}/Payment/Types`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("referenceType", type);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPaymentTerms = async (token, clientId) => {
  const url = new URL(`${baseUrl}/Payment/Terms`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPaymentDeposit = async (token, type, id, clientId) => {
  const url = new URL(`${baseUrl}/${type}/${id}/PaymentDeposit`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPaymentInvoice = async (token, clientId, id, type) => {
  const url = new URL(`${baseUrl}/Payment/InvoiceList`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("referenceId", id);
  url.searchParams.append("referenceType", type);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPaymentInvoiceById = async (
  token,
  clientId,
  id,
  type,
  paymentId
) => {
  const url = new URL(`${baseUrl}/Payment/InvoiceList`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("referenceId", id);
  url.searchParams.append("referenceType", type);
  url.searchParams.append("paymentId", paymentId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const addManualPayment = async (token, data) => {
  const url = new URL(`${baseUrl}/Payment/Confirm`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

//payment api related to update and delete
export const getPaymentData = async (clientId, token, id) => {
  const url = new URL(`${baseUrl}/Payment/${id}`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updatePaymentData = async (token, id, data) => {
  const url = new URL(`${baseUrl}/Payment/Confirm/${id}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const deletePayment = async (
  token,
  id,
  typeId,
  type,
  clientId,
  updateBy,
  updateDate
) => {
  const url = new URL(`${baseUrl}/Payment/${id}`);

  url.searchParams.append("ReferenceId", typeId);
  url.searchParams.append("ReferenceType", type);
  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("LastUpdatedBy", updateBy);
  url.searchParams.append("LastUpdatedDate", updateDate);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const addCreditCard = async (id, body, token) => {
  const url = new URL(`${baseUrl}/Customers/${id}/CreditCards`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const getCardKey = async (token, clientId) => {
  const url = new URL(`${baseUrl}/Payment/CardknoxPublicKey`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postRequestPayment = async (reservationId, data, token,Controller) => {
  const url = new URL(
    `${baseUrl}/${Controller}/${reservationId}/RequestPayment`
  );

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const payByTerminal = async (body, token) => {
  const url = new URL(`${baseUrl}/Payment/PaymentByDevice`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const deleteCreditCard = async (crdeitCard, userId, token) => {
  const url = new URL(`${baseUrl}/Customers/CreditCard`);

  url.searchParams.append("creditCardId", crdeitCard);
  url.searchParams.append("userID", userId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const getCreditCardData = async (clientId, token, id, userID) => {
  const url = new URL(`${baseUrl}/Customers/CreditCard`);

  url.searchParams.append("creditCardId", id);
  url.searchParams.append("clientID", clientId);
  url.searchParams.append("userID", userID);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updateCreditCard = async (token, data) => {
  const url = new URL(`${baseUrl}/Customers/CreditCard`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const addToken = async (body, token) => {
  const url = new URL(`${baseUrl}/Customers/CardknoxToken`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const addAdyenCreditCard = async (body, token) => {
  const url = new URL(`${baseUrl}/Customers/AdyenCreditCard`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};
