import moment from "moment";
import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getVehicles = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Vehicles`);

  for (var key in filtersValues) {
    if (
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== 0 &&
      filtersValues[key] !== null &&
      key !== "Locations"
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  const { Locations } = filtersValues;
  if (Locations && Locations?.length > 0 && typeof Locations === "object") {
    Locations.map((item) => url.searchParams.append("Locations", item));
  } else {
    Locations > 0 && url.searchParams.append("Locations", Locations);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicle = async (clientId, vehicleId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/${vehicleId}`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("GetMakeDetails", true);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const createVehicle = async (body, token) => {
  const url = new URL(`${baseUrl}/Vehicles`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => handleError);
};

export const updateVehicle = async (body, token, vehicleId) => {
  const url = new URL(`${baseUrl}/Vehicles/${vehicleId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((res) => (res.status === 200 ? res : res.json()))
    .catch(handleError);
};

export const deleteVehicle = async (vehicleId, isActive, token) => {
  const url = new URL(`${baseUrl}/Vehicles/${vehicleId}`);

  url.searchParams.append("isActive", isActive);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((res) => res.json())
    .catch(handleError);
};

export const getVehicleStatuses = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/Statuses`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getLeasingCompanies = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/LeasingCompany`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleMakes = async (clientId, isOwnedOnly, token) => {
  const url = new URL(`${baseUrl}/Vehicles/VehicleMake`);

  url.searchParams.append("clientid", clientId);

  if (isOwnedOnly) {
    url.searchParams.append("isOwnedOnly", isOwnedOnly);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleModels = async (filters, token) => {
  const url = new URL(`${baseUrl}/VehicleModels/${filters.MakeId}`);

  url.searchParams.append("clientid", filters.clientId);
  url.searchParams.append("MakeId", filters.MakeId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getFuelTypes = async (token) => {
  const url = new URL(`${baseUrl}/Vehicles/FuelType`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleTransmissions = async (token) => {
  const url = new URL(`${baseUrl}/Vehicles/Transmission`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleDoors = async (token) => {
  const url = new URL(`${baseUrl}/Vehicles/Door`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleFuelLevels = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/FuelLevels`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleLevels = async (token) => {
  const url = new URL(`${baseUrl}/Vehicles/Levels`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleSummary = async (vehicleId, clientId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/${vehicleId}/Summary`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("clientTime", moment().format("YYYY-MM-DDTHH:mm:ss"));

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .catch((error) => error);
};

export const checkVehicleAvailability = async (vehicleId, token) => {
  const url = new URL(
    `${baseUrl}/Vehicles/${vehicleId}/AvailableOpenReservationOrAgreement`
  );

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const checkTypeVehicleAvailability = async (
  clientId,
  vehicleTypeId,
  startDate,
  endDate,
  vehicleId,
  token
) => {
  const url = new URL(`${baseUrl}/Reservations/CheckVehicleAvailability`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("VehicleTypeId", vehicleTypeId);
  url.searchParams.append("StartDate", startDate);
  url.searchParams.append("EndDate", endDate);
  vehicleId > 0 && url.searchParams.append("VehicleId", vehicleId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleExcel = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Vehicles/Excel`);

  for (var key in filtersValues) {
    if (
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== 0 &&
      filtersValues[key] !== null
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  let options = {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `Vehicle.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch(handleError);
};

export const deleteVehicleProfileImage = async (body, token) => {
  const url = new URL(`${baseUrl}/Vehicles/Image`);

  let options = {
    method: "DELETE",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((res) => res.status)
    .catch(handleError);
};

export const GetMaximumVehicles = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/AllowedVehiclesNumber`);
  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Request failed with status code " + response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const GetActiveVehicles = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/ActiveVehicleNumber`);
  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Request failed with status code " + response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const addZubieVehicle = async (vehicleId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/AddZubieVehicle`);

  let options = {
    method: "POST",
    body: JSON.stringify(vehicleId),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const deleteZubieVehicle = async (vehicleId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/DeleteZubieVehicle`);

  let options = {
    method: "DELETE",
    body: JSON.stringify(vehicleId),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const getZubieEnabledForVehicle = async (vehicleId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/ZubieEnabledForVehicle`);

  url.searchParams.append("vehicleId", vehicleId);

  let options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};