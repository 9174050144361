import { IDENTITY_CONFIG } from "../constants/config";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {});

    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      "";
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    if (window.localStorage.getItem("redirectUri")) {
      window.location.replace(window.localStorage.getItem("redirectUri"));
    } else {
      window.location.replace("/home");
    }
  };

  getToken = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );

    if (!!oidcStorage) {
      return oidcStorage.access_token;
    } else {
      return null;
    }
  };

  getProfile = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );

    if (!!oidcStorage) {
      return {
        clientId: oidcStorage.profile.navotar_clientid,
        userId: oidcStorage.profile.navotar_userid,
      };
    } else {
      return null;
    }
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );
    return (
      !!oidcStorage && !!oidcStorage.access_token //&&
      //!this.isTokenExpired(oidcStorage)
    );
  };

  isTokenExpired = (token) => {
    const expirationTime = token.expires_at * 1000 - 60000;
    return Date.now() >= expirationTime;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {})
      .catch((err) => {
        this.logout();
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };
}
