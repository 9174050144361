import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getReports = async (clientId, userId, token) => {
  const url = new URL(`${baseUrl}/Reports`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReport = async (id, clientId, userId, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReportData = async (id, request, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}/Run`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveReportSchedule = async (id, request, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}/Schedule`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updateReportSchedule = async (id, request, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}/Schedule`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const delteteSchedule = async (id, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}/Schedule`);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const saveReport = async (request, token) => {
  const url = new URL(`${baseUrl}/Reports`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReportFolders = async (clientId, userId, token) => {
  const url = new URL(`${baseUrl}/Reports/Folders`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getValPayDepositSummaryData = async (id, request, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}/ValPayDepositSummary`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReportSchedule = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Reports/ReportsSchedule`);
  url.searchParams.append("clientId", clientId);
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(request),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReportSchedulebyID = async (id, token) => {
  const url = new URL(`${baseUrl}/Reports/${id}/ReportsScheduleID`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(request),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
