import { useSelector } from "react-redux";
import { useFeature } from "../auth/permissions";

export function usePhoneNumberFormat() {
  const phoneLengthFeatureValue = useSelector(
    (state) => state.lookupLists.features
  )?.find((e) => e.featureName === "LIMIT_PHONE_NUMBER_LENGTH")?.value;

  var phoneLengthList;
  var lowerLimit = "";
  var upperLimit = "";
  var phoneNoLength = useFeature("LIMIT_PHONE_NUMBER_LENGTH") ? 10 : 10;
  const newPhoneNoPattern = () => {
    if (phoneLengthFeatureValue) {
      phoneLengthList = phoneLengthFeatureValue.split(",");
      if (phoneLengthList.length > 1) {
        lowerLimit = phoneLengthList[0] - 1;
        upperLimit = phoneLengthList[1] - 1;
        return "^[\\+]?[0-9 ][0-9 ]{" + lowerLimit + "," + upperLimit + "}$";
      } else {
        phoneNoLength = phoneLengthList[0];

        return (
          "^[\\+]?([(]?\\+?[/0-9]{1,3}[)]?)?([-.,\\s]{0,1}(?:\\d[-.,\\s]{0,1}){" +
          phoneNoLength +
          "})$"
        );
      }
    } else {
      return (
        "^[\\+]?([(]?\\+?[/0-9]{1,3}[)]?)?([-.,\\s]{0,1}(?:\\d[-.,\\s]{0,1}){" +
        phoneNoLength +
        "})$"
      );
    }
  };

  return {
    newPhoneNoPattern,
  };
}
