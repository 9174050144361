import React, { useState, useEffect } from "react";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";

const CheckBoxModal = (props) => {
  const { name, propChange, list, labelText, disabled } = props;
  const isSelected = (id) => list.indexOf(id) !== -1;
  const [checked, setChecked] = useState(isSelected(parseInt(name)));

  const handleChange = (event) => {
    propChange(event);
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setChecked(isSelected(parseInt(name)));
  }, [list]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name={String(name)}
            id={String(name)}
            disabled={disabled}
          />
        }
        label={
          <Typography color="secondary" variant="body2" display="inline">
            {labelText || ""}
          </Typography>
        }
      />
    </div>
  );
};

export default CheckBoxModal;
