import * as reservationApi from "./reservationApis";
import * as agreementApi from "./agreementApi";
import * as vehicleApi from "./vehicleApi";
import * as usersApi from "./usersApi";
import * as locationApi from "./locationApi";
import * as customerApi from "./customerApi";
import * as insuranceCompaniesApi from "./insuranceCompaniesApi";
import * as referralApi from "./referralApi";
import * as plannerApi from "./plannerApi";
import * as paymentApi from "./paymentApi";
import * as depositApi from "./depositApi";
import * as clientApi from "./clientApi";

export const getLookupLists = async (clientId, token, userId) => {
  let list = {};

  try {
    list.reservationTypes = await reservationApi.getReservationTypes(
      clientId,
      token
    );
  } catch {}

  try {
    list.features = await clientApi.getClientFeatures(clientId, token);
  } catch {}

  try {
    list.reservationStatuses = await reservationApi.getReservationStatuses(
      token
    );
  } catch {}

  try {
    list.agreementTypes = await agreementApi.getAgreementTypes(clientId, token);
  } catch {}

  try {
    list.agreementStatuses = await agreementApi.getAgreementStatuses(
      clientId,
      token
    );
  } catch {}

  try {
    list.vehicleStatuses = await vehicleApi.getVehicleStatuses(clientId, token);
  } catch {}

  try {
    list.countries = await locationApi.getCountries(token);
  } catch {}

  try {
    list.customerTypes = await customerApi.getCustomerTypes(clientId, token);
  } catch {}

  try {
    list.leasingCompanies = await vehicleApi.getLeasingCompanies(
      clientId,
      token
    );
  } catch {}

  try {
    list.vehicleMakes = await vehicleApi.getVehicleMakes(clientId, null, token);
  } catch {}

  try {
    list.insuranceCompanies = await insuranceCompaniesApi.getInsuranceCompanies(
      clientId,
      token
    );
  } catch {}

  try {
    list.referralNames = await referralApi.getReferralNames(clientId, token);
  } catch {}

  try {
    list.users = await usersApi.getUsers(clientId, token);
  } catch {}

  try {
    list.fuelLevels = await vehicleApi.getVehicleFuelLevels(clientId, token);
  } catch {}

  try {
    list.paymentMethods = await paymentApi.getPaymentMethods(
      token,
      1,
      clientId
    );
  } catch {}

  try {
    list.depositMethods = await depositApi.getDepositMethods(clientId, token);
  } catch {}

  try {
    let plannerList = await plannerApi.getPlannerLookupData(
      clientId,
      token,
      userId
    );
    list.reservationPlannerStatuses = plannerList.reservationStatus;
    list.agreementPlannerStatuses = plannerList.agreementStatus;
    list.vehicleTrackStatuses = plannerList.vehicleTrackStatus;
    list.states = plannerList.state;
    list.make = plannerList.vehicleMake;
  } catch {}

  try {
    list.paymentTerms = await paymentApi.getPaymentTerms(token, clientId);
  } catch {}

  try {
    list.screenSettings = await clientApi.getClientScreenSettings(
      clientId,
      token
    );
  } catch {}

  return list;
};
