import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getCustomers = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Customers`);

  for (var key in filtersValues) {
    if (
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== 0 &&
      filtersValues[key] !== "FullName" &&
      filtersValues[key] !== null
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCustomer = async (clientId, customerId, token, userID) => {
  const url = new URL(`${baseUrl}/Customers/${customerId}`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("userID", userID);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const createCustomer = async (clientId, userId, body, token) => {
  const url = new URL(`${baseUrl}/Customers/`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const updateCustomer = async (
  clientId,
  userId,
  body,
  token,
  customerId
) => {
  const url = new URL(`${baseUrl}/Customers/${customerId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const deleteCustomer = async (customerId, isActive, token) => {
  const url = new URL(`${baseUrl}/customers/${customerId}`);

  url.searchParams.append("isActive", isActive);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((res) => res)
    .catch(handleError);
};

export const getCustomerTypes = async (clientId, token) => {
  const url = new URL(`${baseUrl}/customers/types`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCustomerSummary = async (clientId, customerId, token) => {
  const url = new URL(`${baseUrl}/Customers/${customerId}/Summary`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getStates = async (filter, token) => {
  const url = new URL(
    `${baseUrl}/Locations/Countries/${filter.countryId}/States`
  );

  url.searchParams.append("clientid", filter.clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCardTypes = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Payment/CreditCardTypes`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getInsuranceCompanies = async (clientId, token) => {
  const url = new URL(`${baseUrl}/InsuranceCompanies`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getBlacklistReasons = async (token) => {
  const url = new URL(`${baseUrl}/Customers/BlackListReasons`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const uploadToAws = async (customerId, body, token) => {
  const url = new URL(`${baseUrl}/Customer/${customerId}/Documents/Images`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const uploadImageInCustomerProfile = async (
  customerId,
  clientId,
  userId,
  imageBody,
  token
) => {
  let documentUrl = null;

  await uploadToAws(customerId, imageBody, token)
    .then((res) => {
      documentUrl = res.documentUrl;
      return res;
    })
    .then(() => {
      const url = new URL(`${baseUrl}/Damages/Images`);

      let profileBody = {
        clientId: parseInt(clientId),
        VehicleId: 0,
        customerId: customerId,
        referenceId: customerId,
        module: "Customer",
        userId: parseInt(userId),
        uploadedDate: new Date().toISOString(),
        isDeleted: false,
        Title: "Customer Image",
        imageUrl: documentUrl,
      };

      let options = {
        method: "POST",
        body: JSON.stringify(profileBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(url, options)
        .then((response) => response)
        .catch(handleError);
    });
};

export const getTrafficListByCustomerId = async (clientId, id, token) => {
  const url = new URL(`${baseUrl}/TrafficTickets`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("CustomerId", id);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCustomerExcel = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Customers/Excel`);

  for (var key in filtersValues) {
    if (
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== 0 &&
      filtersValues[key] !== "FullName" &&
      filtersValues[key] !== null
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  let options = {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `Customer.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch(handleError);
};

export const deleteCustomerProfileImage = async (imageId, token) => {
  const url = new URL(`${baseUrl}/Customers/Image`);

  url.searchParams.append("imageId", imageId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((res) => res.status)
    .catch(handleError);
};
