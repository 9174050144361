import { combineReducers } from "redux";
import lookupIds from "./lookupIdReducer";
import lookupLists from "./lookupListsReducer";
import user from "./userReducer";
import addReservation from "./addReservationReducer";
import editReservation from "./editReservationReducer";
import editAgreement from "./editAgreementReducer";
import damagePrint from "./damagePrintReducer";
import addCustomer from "./addCustomerReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  lookupLists,
  user,
  addReservation,
  editReservation,
  editAgreement,
  lookupIds,
  damagePrint,
  addCustomer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
