import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getVehicleTypes = async (clientId, userId, token) => {
  const url = new URL(`${baseUrl}/Vehicles/Types`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleTypesDetail = async (clientId, token, LocationID) => {
  const url = new URL(`${baseUrl}/VehicleTypes`);

  url.searchParams.append("clientid", clientId);

  if (LocationID) {
    url.searchParams.append("LocationID", LocationID);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleTypesByLocation = async (
  clientId,
  LocationID,
  token,
  startDate,
  endDate,
  baseRate,
  fields
) => {
  const baseRatenew = baseRate || 0;
  // if (baseRate !== undefined && baseRate > 0) {
  //   baseRatenew = baseRate || 0;
  // }
  const url = new URL(`${baseUrl}/VehicleTypes`);
  url.searchParams.append("clientid", clientId);
  url.searchParams.append("LocationID", LocationID);
  url.searchParams.append("VehicleTypeID", 0);
  url.searchParams.append("StartDate", startDate);
  url.searchParams.append("EndDate", endDate);
  url.searchParams.append("BaseRate", baseRatenew);
  url.searchParams.append("Fields", fields);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getOneVehicleTypeDetail = async (clientId, token, id) => {
  const url = new URL(`${baseUrl}/VehicleTypes/${id}`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleByTypeId = async (
  clientId,
  userId,
  vehicleTypeId,
  token
) => {
  const url = new URL(`${baseUrl}/Vehicles`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("UserId", userId);
  url.searchParams.append("VehicleTypeId", vehicleTypeId);
  url.searchParams.append("PageSize", -1);
  url.searchParams.append("Active", true);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleBaggage = async (token) => {
  const url = new URL(`${baseUrl}/VehicleTypes/Baggage`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteVehicleType = async (token, id, clientId, userId) => {
  const url = new URL(`${baseUrl}/VehicleTypes/${id}`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const addVehicleType = async (token, data) => {
  const url = new URL(`${baseUrl}/VehicleTypes`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const updateVehicleType = async (token, id, data, clientId) => {
  const url = new URL(`${baseUrl}/VehicleTypes/${id}`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const deleteVehicleTypeImage = async (token, id, clientId, userId) => {
  const url = new URL(`${baseUrl}/VehicleTypes/VehicleTypeImage/${id}`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const addVehicleTypeImage = async (token, data) => {
  const url = new URL(`${baseUrl}/VehicleTypes/VehicleTypeImage`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const addVehicleDamageImage = async (token, data) => {
  const url = new URL(`${baseUrl}/VehicleTypes/DamageImage`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const deleteVehicleDamageImage = async (token, id, clientId) => {
  const url = new URL(`${baseUrl}/VehicleTypes/VehicleTypeDamageImage/${id}`);
  url.searchParams.append("clientid", clientId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};
