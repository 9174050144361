import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getNavotarLink = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Users/NavotarLink`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getUsers = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Users`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getUser = async ({ clientId, userId }, token) => {
  let userProfile = await getUserProfile({ clientId, userId }, token);

  userProfile.permissions = await getUserPermissions(
    { clientId, userId },
    token
  );

  return userProfile;
};

export const getUserProfile = async ({ clientId, userId }, token) => {
  const url = new URL(`${baseUrl}/Users/${userId}`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getUserPermissions = async ({ clientId, userId }, token) => {
  const url = new URL(`${baseUrl}/Users/${userId}/Permissions`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getLanguage = async (token) => {
  const url = new URL(`${baseUrl}/Users/Language`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getUserConfig = async (clientId, userId, token) => {
  const url = new URL(`${baseUrl}/Users/UserConfiguration`);

  url.searchParams.append("Clientid", clientId);

  url.searchParams.append("UserId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getUserByID = async (clientId, userId, token) => {
  const url = new URL(`${baseUrl}/Users/${userId}`);

  url.searchParams.append("Clientid", clientId);

  url.searchParams.append("CurrentUserId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveUser = async (data, token) => {
  const url = new URL(`${baseUrl}/Users`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const GetMaximumUsers = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Users/MaximumUser`);
  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Request failed with status code " + response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const GetActiveUsersCount = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Users/ActiveUsers`);
  url.searchParams.append("clientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Request failed with status code " + response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const updateUser = async (userId, data, token) => {
  const url = new URL(`${baseUrl}/Users/${userId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const resetPassword = async (data, token) => {
  const url = new URL(`${baseUrl}/Users/ResetPassword`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const mailResetPassword = async (data, token) => {
  const url = new URL(`${baseUrl}/Users/ResetPassword`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const resetMultiplePassword = async (
  clientId,
  userlist,
  userId,
  confirmPassword,
  token
) => {
  const url = new URL(`${baseUrl}/Users/ChangePassword`);

  url.searchParams.append("ClientId", clientId);

  url.searchParams.append("Userlist", userlist);

  url.searchParams.append("CurrentUserId", userId);

  url.searchParams.append("ConfirmPassword", confirmPassword);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (
    fetch(url, options)
      // .then((response) => response)
      .then((response) => response.json())
      .then((responseData) => {
        return responseData;
      })
      .catch(handleError)
  );
};

export const generatePasswordResetLink = async (data, token) => {
  const url = new URL(`${baseUrl}/Users/SendPasswordResetLink`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

//Begin - RNTL-2861
export const ValidateResetPasswordURL = async (data) => {
  const url = new URL(`${baseUrl}/PublicUser/ValidateResetPasswordURL`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const publicPasswordReset = async (data) => {
  const url = new URL(`${baseUrl}/PublicUser/PublicPasswordReset`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const fetchLink = async (clientId, token) => {
  const url = `${baseUrl}/Messages?ClientId=${clientId}`;
  const options = {
    method: "GET",
    headers: {
      "accept": "*/*",
      "Authorization": `Bearer ${token}`
    }
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Request failed with status code " + response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


//End - RNTL-2861
